<template>
  <v-container class="pa-9">
    <h1>
      Cấu hình Giáo viên
    </h1>
    <h4>List giáo viên hiện chi tiết</h4>
    <v-row class="align-center justify-end">
      <v-btn
          color="primary"
          dark
          class="mb-2 text-body-2"
          @click="goToCreateTeacherDetail"
      >{{ addSlide }}
      </v-btn>
    </v-row>
    <v-data-table
        v-if="listTeacherDetail && listTeacherDetail.length > 0"
        :headers="headers"
        :items="listTeacherDetail"
        class="elevation-1"
    >
      <template v-slot:item.image_src="{ item }">
        <v-img
            :src="item.image_src"
            :alt="item.name"
            :title="item.name"
            width="150"
            height="150"
        ></v-img>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
        <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
      </template>
    </v-data-table>
    <v-divider></v-divider>
    <h4>List giáo viên hiện list</h4>
    <v-row class="align-center justify-end">
      <v-btn
          color="primary"
          dark
          class="mb-2 text-body-2"
          @click="goToCreateTeacherList"
      >{{ addSlide }}
      </v-btn>
    </v-row>
    <v-data-table
        v-if="listTeacherList && listTeacherList.length > 0"
        :headers="headers"
        :items="listTeacherList"
        class="elevation-1"
    >
      <template v-slot:item.image_src="{ item }">
        <v-img
            :src="item.image_src"
            :alt="item.name"
            :title="item.name"
            width="150"
            height="150"
        ></v-img>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItemList(item)">mdi-pencil</v-icon>
        <v-icon small @click="deleteItemList(item)">mdi-delete</v-icon>
      </template>
    </v-data-table>
    <CreateTeacherDetailDialog
        :teacherId="teacherId"
        :dialog="showCreateTeacherDetailDialog"
        @onClickOutside="showCreateTeacherDetailDialog = false"
        @closePopup="closePopup"
    ></CreateTeacherDetailDialog>
    <CreateTeacherListDialog
        :teacherId="teacherId"
        :dialog="showCreateTeacherListDialog"
        @onClickOutside="showCreateTeacherListDialog = false"
        @closePopup="closePopup1"
    ></CreateTeacherListDialog>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";
import {
  GET_TEACHER_CONF,
  SAVE_CONF,
  GET_CONFIG,
  DELETE_TEACHER,
  DELETE_CURRENT_TEACHER
} from "@/store/setting.module";
import CreateTeacherDetailDialog from "@/views/dialogs/create-teacher-detail.vue";
import CreateTeacherListDialog from "@/views/dialogs/create-teacher-list.vue";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
export default {
  data() {
    return {
      headers: [
        {text: "STT", value: "id"},
        {text: "Tên", value: "name"},
        {text: "Ảnh", value: "image_src"},
        {text: "Sửa/Xóa", value: "actions", sortable: false}
      ],
      editedIndex: -1,
      teacherId: null,
      showCreateTeacherDetailDialog: false,
      showCreateTeacherListDialog: false,
      addSlide: "Thêm giáo viên",
      listTeacherList : [],
      listTeacherDetail : [],
    };
  },
  computed: {
    ...mapGetters({
      setting: "setting"
    }),
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    setting(newVal){
      if (newVal && newVal.listTeacherList){
        this.listTeacherList = newVal.listTeacherList
      }
      if (newVal &&  newVal.listTeacherDetail){
        this.listTeacherDetail = newVal.listTeacherDetail
      }
    }
  },
  components: {
    CreateTeacherDetailDialog,
    CreateTeacherListDialog
  },
  mounted() {
    let key = {
      'key': 'teacher'
    }
    this.$store.dispatch(GET_CONFIG, key);
  },
  created() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Cấu hình giáo viên",titledad:'Cấu hình' }]);
  },
  methods: {
    goToCreateTeacherDetail() {
      this.showCreateTeacherDetailDialog = true;
    },
    goToCreateTeacherList() {
      this.showCreateTeacherListDialog = true;
    },
    editItem(item) {
      let payload = {
        id: item.id,
        key: 'listTeacherDetail'
      }
      this.$store.commit(GET_TEACHER_CONF, payload);
      this.teacherId = item.id;
      this.showCreateTeacherDetailDialog = true;
    },
    editItemList(item) {
      let payload = {
        id: item.id,
        key: 'listTeacherList'
      }
      this.$store.commit(GET_TEACHER_CONF, payload);
      this.teacherId = item.id;
      this.showCreateTeacherListDialog = true;
    },
    async deleteItem(item) {
      let payload = {
        id: item.id,
        key: 'listTeacherDetail'
      }
      this.$store.commit(DELETE_TEACHER, payload);
      let data = {
        key: "teacher",
        value: this.setting
      };
      confirm("Are you sure you want to delete this item?") &&
      (await this.$store.dispatch(SAVE_CONF, data));
      await this.reloadConfig()
    },
    async deleteItemList(item) {
      let payload = {
        id: item.id,
        key: 'listTeacherList'
      }
      this.$store.commit(DELETE_TEACHER, payload);
      let data = {
        key: "teacher",
        value: this.setting
      };
      confirm("Are you sure you want to delete this item?") &&
      (await this.$store.dispatch(SAVE_CONF, data));
      await this.reloadConfig()
    },

    close() {
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closePopup() {
      this.$store.commit(DELETE_CURRENT_TEACHER)
      this.reloadConfig();
      this.showCreateTeacherDetailDialog = false;
    },
    closePopup1() {
      this.reloadConfig();
      this.$store.commit(DELETE_CURRENT_TEACHER)
      this.showCreateTeacherListDialog = false;
    },
    reloadConfig() {
      let key = {
        'key': 'teacher'
      }
      this.$store.dispatch(GET_CONFIG, key);
    }
  }
};
</script>

<style lang="scss"></style>
