var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-9"},[_c('h1',[_vm._v(" Cấu hình Giáo viên ")]),_c('h4',[_vm._v("List giáo viên hiện chi tiết")]),_c('v-row',{staticClass:"align-center justify-end"},[_c('v-btn',{staticClass:"mb-2 text-body-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.goToCreateTeacherDetail}},[_vm._v(_vm._s(_vm.addSlide)+" ")])],1),(_vm.listTeacherDetail && _vm.listTeacherDetail.length > 0)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.listTeacherDetail},scopedSlots:_vm._u([{key:"item.image_src",fn:function(ref){
var item = ref.item;
return [_c('v-img',{attrs:{"src":item.image_src,"alt":item.name,"title":item.name,"width":"150","height":"150"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")])]}}],null,false,1809818553)}):_vm._e(),_c('v-divider'),_c('h4',[_vm._v("List giáo viên hiện list")]),_c('v-row',{staticClass:"align-center justify-end"},[_c('v-btn',{staticClass:"mb-2 text-body-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.goToCreateTeacherList}},[_vm._v(_vm._s(_vm.addSlide)+" ")])],1),(_vm.listTeacherList && _vm.listTeacherList.length > 0)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.listTeacherList},scopedSlots:_vm._u([{key:"item.image_src",fn:function(ref){
var item = ref.item;
return [_c('v-img',{attrs:{"src":item.image_src,"alt":item.name,"title":item.name,"width":"150","height":"150"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItemList(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItemList(item)}}},[_vm._v("mdi-delete")])]}}],null,false,49985785)}):_vm._e(),_c('CreateTeacherDetailDialog',{attrs:{"teacherId":_vm.teacherId,"dialog":_vm.showCreateTeacherDetailDialog},on:{"onClickOutside":function($event){_vm.showCreateTeacherDetailDialog = false},"closePopup":_vm.closePopup}}),_c('CreateTeacherListDialog',{attrs:{"teacherId":_vm.teacherId,"dialog":_vm.showCreateTeacherListDialog},on:{"onClickOutside":function($event){_vm.showCreateTeacherListDialog = false},"closePopup":_vm.closePopup1}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }