var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"600","light":""},on:{"click:outside":_vm.onClickOutside},model:{value:(_vm.showModel),callback:function ($$v) {_vm.showModel=$$v},expression:"showModel"}},[_c('v-card',{staticClass:"pa-0 ma-0 pa-3"},[_c('v-container',[_c('h3',[_vm._v("Thêm mới giáo viên hiện chi tiết")]),_c('ValidationObserver',{ref:"add_question"},[_c('div',[_c('h5',[_vm._v("1.Tên Giáo Viên")]),_c('ValidationProvider',{attrs:{"name":'name',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Input',{attrs:{"name":'name',"text":_vm.teacher.name,"label":'Nhập tiêu đề'},model:{value:(_vm.teacher.name),callback:function ($$v) {_vm.$set(_vm.teacher, "name", $$v)},expression:"teacher.name"}}),_c('span',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])}),_c('h5',[_vm._v("2.Hình ảnh (kích thước ảnh 350x230)")]),_c('v-file-input',{staticClass:"pl-1",attrs:{"name":"image","prepend-icon":"mdi-camera","label":"Tải ảnh","accept":"image/png, image/jpeg, image/bmp, image/webp"},on:{"change":_vm.onChangeFileUpload,"click:clear":_vm.clearImage},model:{value:(_vm.image),callback:function ($$v) {_vm.image=$$v},expression:"image"}}),(_vm.show)?_c('div',{attrs:{"id":"preview"}},[_c('v-img',{attrs:{"src":_vm.teacher.image_src,"width":"150px"}})],1):_vm._e(),_c('span',{staticClass:"validate-error text-danger"},[_vm._v(_vm._s(_vm.imageError))]),_c('h5',[_vm._v("3.Link facebook")]),_c('ValidationProvider',{attrs:{"name":'facebook',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Input',{attrs:{"name":'facebook',"text":_vm.teacher.facebook,"label":'Nhập link facebook'},model:{value:(_vm.teacher.facebook),callback:function ($$v) {_vm.$set(_vm.teacher, "facebook", $$v)},expression:"teacher.facebook"}}),_c('span',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])}),_c('h5',[_vm._v("4.Link Youtube")]),_c('ValidationProvider',{attrs:{"name":'youtube',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Input',{attrs:{"name":'youtube',"text":_vm.teacher.youtube,"label":'Nhập link Youtube'},model:{value:(_vm.teacher.youtube),callback:function ($$v) {_vm.$set(_vm.teacher, "youtube", $$v)},expression:"teacher.youtube"}}),_c('span',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])}),_c('h5',[_vm._v("5.Link instagram")]),_c('ValidationProvider',{attrs:{"name":'instagram',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Input',{attrs:{"name":'instagram',"text":_vm.teacher.instagram,"label":'Nhập link instagram'},model:{value:(_vm.teacher.instagram),callback:function ($$v) {_vm.$set(_vm.teacher, "instagram", $$v)},expression:"teacher.instagram"}}),_c('span',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)]),_c('v-row',{staticClass:"align-center justify-center"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.saveSliderConfig}},[_vm._v(_vm._s(_vm.save))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }