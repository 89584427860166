<template>
  <v-dialog
      v-model="showModel"
      @click:outside="onClickOutside"
      max-width="600"
      light
  >
    <v-card class="pa-0 ma-0 pa-3">
      <v-container>
        <h3>Thêm mới giáo viên hiện chi tiết</h3>
        <ValidationObserver ref="add_question">
          <div>
            <h5>1.Tên Giáo Viên</h5>
            <ValidationProvider
                :name="'name'"
                rules="required"
                v-slot="{ errors }"
            >
              <Input
                  :name="'name'"
                  v-model="teacher.name"
                  :text="teacher.name"
                  :label="'Nhập tiêu đề'"
              />
              <span class="validate-error text-danger text-danger">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
            <h5>2.Hình ảnh (kích thước ảnh 350x230)</h5>
            <v-file-input
                name="image"
                class="pl-1"
                prepend-icon="mdi-camera"
                v-model="image"
                label="Tải ảnh"
                accept="image/png, image/jpeg, image/bmp, image/webp"
                @change="onChangeFileUpload"
                @click:clear="clearImage"
            ></v-file-input>
            <div id="preview" v-if="show">
              <v-img :src="teacher.image_src" width="150px"></v-img>
            </div>
            <span class="validate-error text-danger">{{ imageError }}</span>
            <h5>3.Link facebook</h5>
            <ValidationProvider
                :name="'facebook'"
                rules="required"
                v-slot="{ errors }"
            >
              <Input
                  :name="'facebook'"
                  v-model="teacher.facebook"
                  :text="teacher.facebook"
                  :label="'Nhập link facebook'"
              />
              <span class="validate-error text-danger text-danger">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
            <h5>4.Link Youtube</h5>
            <ValidationProvider
                :name="'youtube'"
                rules="required"
                v-slot="{ errors }"
            >
              <Input
                  :name="'youtube'"
                  v-model="teacher.youtube"
                  :text="teacher.youtube"
                  :label="'Nhập link Youtube'"
              />
              <span class="validate-error text-danger text-danger">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
            <h5>5.Link instagram</h5>
            <ValidationProvider
                :name="'instagram'"
                rules="required"
                v-slot="{ errors }"
            >
              <Input
                  :name="'instagram'"
                  v-model="teacher.instagram"
                  :text="teacher.instagram"
                  :label="'Nhập link instagram'"
              />
              <span class="validate-error text-danger text-danger">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </div>
        </ValidationObserver>
        <v-row class="align-center justify-center">
          <v-btn color="primary" @click="saveSliderConfig">{{ save }}</v-btn>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters} from "vuex";
import {SAVE_CONF,DELETE_CURRENT_TEACHER} from "@/store/setting.module";
import {UPLOAD_IMAGE} from "@/store/media.module";

export default {
  props: {
    dialog: {type: Boolean, default: false},
    teacherId: {type: Number, default: 0}
  },
  data() {
    return {
      show: false,
      edit: false,
      save: 'Lưu',
      teacher: {
        id: null,
        name: "",
        instagram : '',
        zalo : '',
        youtube : '',
        facebook : '',
        image_src : '',
      },
      image: {},
      imageError : ''
    };
  },
  watch: {
    imageUrl() {
      this.teacher.image_src = this.imageUrl;
      if (this.teacher.image_src) {
        this.show = true;
      }
    },
    currentTeacher: {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.teacher = newVal;
          if (this.teacher.image_src) {
            this.show = true;
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      setting : 'setting',
      currentTeacher : 'currentTeacher',
      imageUrl :'imageUrl'
    }),
    showModel: {
      get: function () {
        return this.dialog;
      },
      // eslint-disable-next-line no-unused-vars
      set: function (newValue) {
      }
    }
  },
  components: {
    Input: () => import("@/components/elements/hnr-input")
  },
  created() {
    this.show = false;
    this.image = {};
  },
  methods: {
    clearImage() {
      this.teacher.image_src = "";
      this.image = {}
      this.show = false;
    },
    onChangeFileUpload() {
      if (this.image){
        this.imageError = "";
        let formData = new FormData();
        formData.append("upload", this.image);
        this.$store.dispatch(UPLOAD_IMAGE, formData).then((data)=>{
          if (!data.data.status && data.data.errorCode == 422){
            this.$toasted.error("Hình ảnh chưa đúng định dạng !!", {
              position: "top-right",
              duration : 3000
            })
          }
        });
      }
    },
    onClickOutside() {
      this.resetForm()
      this.$emit("onClickOutside");
    },
    checkValue() {
      if (this.teacher.image_src){
        if (this.teacher.id === null && this.teacherId !== null) {
          this.teacher.id = this.teacherId;
          return true
        }
        this.teacher.id = this.getMaxKey() + 1;
        return true
      }
      else {
        this.$toasted.error("Bạn chưa upload hình ảnh !!", {
          position: "top-right",
          duration : 3000
        })
        return false
      }
    },
    getDataPost() {
      let value = this.setting.listTeacherList ? this.setting.listTeacherList : [];
      value.map((slide, i) => {
        if (slide.id == this.teacher.id) {
          value[i] = this.teacher;
          this.edit = true;
        }
      });
      if (!this.edit) {
        value.push(this.teacher);
      }
      this.setting.listTeacherList = value
      let data = {
        key: "teacher",
        value: this.setting
      };
      return data;
    },
    async saveSliderConfig() {
      let result = await this.$refs.add_question.validate()
      if (result) {
        let check = this.checkValue();
        if (check) {
          let data = this.getDataPost()
          this.$store.dispatch(SAVE_CONF, data).then(data => {
            if (data.status) {
              this.$store.commit(DELETE_CURRENT_TEACHER)
              this.$emit("closePopup");
              this.clearImage()
              this.resetForm()
              this.$toasted.success('Lưu thành công', {
                position: "top-right",
                theme: "toasted-primary",
                duration: 1000
              });
            }
          });
          this.edit = false
        }
      }else {
        this.$toasted.error("Bạn chưa nhập đủ thông tin !!", {
          position: "top-right",
          duration : 3000
        })
        return false
      }
    },
    resetForm() {
      this.teacher = {
        id: null,
        name: "",
        instagram : '',
        youtube : '',
        facebook : '',
        image_src : '',
      };
      this.show = false;
      this.imageUrl = '';
      this.image = null
    },
    getMaxKey(){
      let max = 1
      if (this.setting && this.setting.listTeacherList) {
        this.setting.listTeacherList.map((item)=>{
          if (item.id >= max){
            max = item.id
          }
        })
      }
      return max
    }
  }
};
</script>
